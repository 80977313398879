import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getallplan, createplan, updateplan, deleteplan } from '../../ReduxToolkit/Slice/Plan/Plan';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Wrap from '../../components/Wrapper/Wrap';
import PageTitle from '../../components/Title/PageTitle';
import Table from '../../components/Table/Table';

function Plan() {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [duration, setDuration] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [updateButton, setUpdateButton] = useState("d-none");
  const [submitButton, setSubmitButton] = useState("");

  const dispatch = useDispatch();
  const { plans, status } = useSelector((state) => state.plan);

  useEffect(() => {
   if (status === "idle") {
    dispatch(getallplan());
   }
  }, [dispatch,status]);

  const handleCreatePlan = () => {
    dispatch(createplan({ name, price, duration }));
    setName("");
    setPrice("");
    setDuration("");
    setSelectedId("");
  };

  const handleEdit = (id) => {
    const selectedPlan = plans.find(plan => plan._id === id);
    if (selectedPlan) {
      setName(selectedPlan.name);
      setPrice(selectedPlan.price);
      setDuration(selectedPlan.duration);
      setSelectedId(id);
      setUpdateButton("");
      setSubmitButton("d-none");
    }
  };

  const handleUpdatePlan = () => {
    const data = {
      name,
      price,
      duration
    };
    dispatch(updateplan({ selectedId, data }));
    setName("");
    setPrice("");
    setDuration("");
    setSelectedId("");
    setUpdateButton("d-none");
    setSubmitButton("");
  };

  const handleDelete = (planId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Plan?");
    if (isConfirmed) {
      dispatch(deleteplan(planId));
    }
  };
  //   table destructure
  const columns = [
    { label: "Plan Name", accessor: "name" },
    { label: "Plan Price", accessor: "price" },
    { label: "Duration", accessor: "duration" },
  ];
  return (
    <Wrap className="container-fluid marginTop">
      <Wrap className="row justify-content-center">
        <Wrap className="col-lg-12 py-2">
          <PageTitle children={"Create Plan"} />
          <Wrap className="row my-2 justify-content-between">
            <Wrap className="col-lg-3 my-2">
              <label htmlFor="" className='my-1'>Select Plan</label>
              <select name="" id="" className='form-control' required value={name}
                onChange={(e) => setName(e.target.value)} >
                 <option value="select Plan">Select Plan</option>
                <option value="Bronze">Bronze</option>
                <option value="Silver">Silver</option>
                <option value="Gold">Gold</option>
                <option value="Diamond">Diamond</option>
              </select>
            </Wrap>
            <Wrap className="col-lg-3 my-2">
              <Input
                label="Month Validity"
                name="duration"
                type="text"
                placeholder="Ex- 1,3,6,12,"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                required
              />
            </Wrap>
            <Wrap className="col-lg-3 my-2">
              <Input
                label="Price"
                name="price"
                type="text"
                placeholder="Rs. 1000 /-"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </Wrap>
            <Wrap className='col-lg-3 my-2'>
              <Wrap className="my-1">Create New Plan</Wrap>
            <Button children={"Create"} onClick={handleCreatePlan} className={`${submitButton}`} />
            <Button children={"Update"} onClick={handleUpdatePlan} className={`${updateButton}`} />
          </Wrap>
          </Wrap>
         
          <Table
            columns={columns}
            data={plans || []}
            status={status}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </Wrap>
      </Wrap>
    </Wrap>
  );
}

export default Plan;
