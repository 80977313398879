import React from 'react'
import { NavLink } from 'react-router-dom'
import { BgColor, TextColor } from '../../components/Color/Color';
function Siderbar() {
    const menuItem = [
        {
            id: 1,
            name: "Home",
            link: "",
            icon: "house",
        },
        {
            id: 2,
            name: "Gyms",
            link: "gyms",
            icon: "trophy",
        },
        {
            id: 3,
            name: "Plan",
            link: "plan",
            icon: "ui-checks-grid",
        },
        
        {
            id: 3,
            name: "Members",
            link: "users",
            icon: "people",
        },
        // {
        //     id: 9,
        //     name: "Banner",
        //     link: "banner",
        //     icon: "card-image",
        // },
        {
            id: 5,
            name: "Notification",
            link: "notification",
            icon: "bell",
        },
        {
            id: 6,
            name: "Support",
            link: "support",
            icon: "question-circle",
        },

        {
            id: 7 ,
            name: "Feedback",
            link: "feedback",
            icon: "chat-left",
        },

    ]
    return (
        <div
            className="d-flex flex-column flex-shrink-0"
        >
           
            <ul className="nav nav-pills flex-column mb-auto">
                {
                    menuItem.map((_) => (
                        <li className="nav-item" key={_.id}>
                            <NavLink to={_.link} className={({ isActive }) => `nav-link text-dark ${isActive ? `bg-${BgColor} text-${TextColor}` : ""}`}>
                                <i className={`bi bi-${_.icon} me-2`}>
                                </i>
                                {_.name}
                            </NavLink>
                        </li>
                    ))
                }
            </ul>


        </div>
    )
}

export default Siderbar