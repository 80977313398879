import React, { useState, useEffect } from 'react'
import axios from "axios";
import { total } from '../../Api_url';
import { Link } from 'react-router-dom';
function Home() {
  const token = localStorage.getItem("token")
  console.log(token)
const headers = {
    'Content-Type': 'application/json', 
    'Authorization': `Bearer ${token}`
};
  const [totalValue, setTotalValue] = useState([])
  const [loading, setLoading] = useState(true);
  console.log(totalValue)
  const totalapi = async () => {
    try {
      const res = await axios.get(total,{headers})
      setTotalValue(res.data)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }
  useEffect(() => {
    totalapi()
  })
  const cards = [
    {
      id: 1,
      title: "Members",
      count: loading ? <div className="spinner-border  disabled" style={{ color: "white" }} role="status">
        <span className="visually-hidden">Loading...</span>
      </div> : totalValue.UserCount,
      icon: <i className='bi bi-people display-6'></i>,
      link:"Users"
    },
    {
      id: 2,
      title: "Gyms",
      count: loading ? <div className="spinner-border  disabled" style={{ color: "white" }} role="status">
        <span className="visually-hidden">Loading...</span>
      </div> : totalValue.GymCount,
      icon: <i className='bi bi-trophy display-6'></i>,
      link:"gyms"
    },
    {
      id: 3,
      title: "Plan",
      count: loading ? <div className="spinner-border  disabled" style={{ color: "white" }} role="status">
        <span className="visually-hidden">Loading...</span>
      </div> : totalValue.GymPlanCount,
      icon:<i className='bi bi-ui-checks-grid display-6'></i>,
      link:"plan"
    },

    {
      id: 4,
      title: "Support",
      count: loading ? <div className="spinner-border  disabled" style={{ color: "white" }} role="status">
        <span className="visually-hidden">Loading...</span>
      </div> : totalValue.GymSupportCount,
      icon:<i className='bi bi-question-circle display-6'></i>,
      link:"support"
    }
  ]
  return (
    <>
      <div className="container-fluid marginTop">
        <div className="row justify-content-center">
          <div className="col-lg-12 py-2">
            <h3>Dashbord</h3>
            <div className="row">
              {cards.map((data) => {
                const { title, icon, id, count,link } = data;
                return (
                  <div className="col-lg-4 col-6 mb-3" key={id}>
                    <Link to={`${link}`}>
                      <div className='d-flex justify-content-between p-3 bg-dark shadow-sm rounded border-design'>
                        <div className='content-center'>
                          <span className='text-white'>
                            {icon}  
                          </span>
                        </div>
                        <div>
                          <h4 className='text-white'>{title}</h4>
                          <div className='text-white display-6 text-end fw-bold'>{count}</div>
                        </div>
                      </div></Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home