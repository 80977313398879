import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { register_gym } from '../../ReduxToolkit/Slice/Gym/GymSlice';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';


function AddGyms() {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [gymName, setGymName] = useState("");
    const [ownerName, setOwnerName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPincode] = useState("");
    const [image, setImage] = useState(null);
    const [password, setPassword] = useState("");


    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('gymName', gymName);
            formData.append('ownerName', ownerName);
            formData.append('phone', phone);
            formData.append('email', email);
            formData.append('address', address);
            formData.append('city', city);
            formData.append('state', state);
            formData.append('pincode', pincode);
            formData.append('image', image);
            formData.append('password', password);
            dispatch(register_gym(formData));

            setGymName("");
            setOwnerName("");
            setPhone("");
            setEmail("");
            setAddress("");
            setCity("");
            setState("");
            setPincode("");
            setImage(null);
            setPassword("");
            navigation("/gyms");
        } catch (error) {
            console.log("Error in Submission", error);
        }
    };

    return (
        <div className="container-fluid marginTop">
            <div className="row justify-content-center">
                <div className="col-lg-12 py-2">
                    <h3>List Gyms</h3>
                    <h4 className='text-center'>Provide Gym Details</h4>
                    <form onSubmit={handleSubmit}>
                        <div className="row my-2 justify-content-between">
                            <div className="col-lg-6 my-2">
                                <Input
                                    label="Gym Name"
                                    name="gymName"
                                    placeholder="Enter Gym Name"
                                    value={gymName}
                                    onChange={(e) => setGymName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    label="Owner Name"
                                    name="ownerName"
                                    placeholder="Enter Owner Name"
                                    value={ownerName}
                                    onChange={(e) => setOwnerName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    label="Phone Number"
                                    name="phone"
                                    placeholder="Enter Phone Number"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    maxLength="10"
                                    required
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    label="Email"
                                    name="email"
                                    placeholder="Enter Email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    label="Address"
                                    name="address"
                                    placeholder="Enter Address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    label="City"
                                    name="city"
                                    placeholder="Enter City"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    label="State"
                                    name="state"
                                    placeholder="Enter State"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    label="Pincode"
                                    name="pincode"
                                    placeholder="Enter Pincode"
                                    type="number"
                                    value={pincode}
                                    onChange={(e) => setPincode(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    label="Password"
                                    name="password"
                                    placeholder="Enter Password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    label="Upload Profile Image"
                                    type="file"
                                    onChange={(e) => setImage(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className='text-center'>
                            <Button children={"Create Gym"} type='submit' />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddGyms;
