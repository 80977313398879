import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getallgym } from "../../ReduxToolkit/Slice/Gym/GymSlice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { sendnotificationgym } from "../../ReduxToolkit/Slice/Notification/Notification";
import Input from "../../components/Input/Input";
import Model from "../../components/Model/Model";
import Button from "../../components/Button/Button";
import Wrap from "../../components/Wrapper/Wrap";

function Gyms() {
  const dispatch = useDispatch();
  const { gyms, status, error } = useSelector((state) => state.gym);
  const [filter, setFilter] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [gymId, setId] = useState("");

  useEffect(() => {
    if (status === "idle") {
      dispatch(getallgym());
    }
  }, [dispatch]);

  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  // notifcation
  const handleId = (id) => () => {
    setId(id);
  };

  const SendNotificationButton = () => {
    if (!title.trim() || !description.trim() || !gymId) {
      alert("All fields are required!");
      return;
    }
    const data = {
      title: title,
      description: description,
      gymId: gymId,
    };
    dispatch(sendnotificationgym(data));
    setTitle("");
    setDescription("");
    setId("");
  };

  return (
    <>
      <Wrap className="container-fluid marginTop">
        <Wrap className="row justify-content-center">
          <Wrap className="col-lg-12 py-2">
            <h3>Gyms</h3>
            <Wrap className="row my-2 justify-content-between">
              <Wrap className="col-lg-2 col-6">
                <Wrap className="form-group">
                  <label htmlFor="filter" className="form-label">
                    Filter
                  </label>
                  <select
                    id="filter"
                    value={filter}
                    onChange={handleChange}
                    className="form-select"
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </select>
                </Wrap>
              </Wrap>
              <Wrap className="col-lg-3 col-6">
                <Input
                  id="search"
                  label="Search"
                  placeholder="Search"
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Wrap>
              <Wrap className="col-lg-3">
                <Wrap htmlFor="">Create Gym</Wrap>
                <Link to={"/addgyms"}>
                  <Button children={"Create"} icon="plus" />
                </Link>
              </Wrap>
            </Wrap>

            <Wrap className="table-responsive">
              <table className="table table-dark table-striped rounded table-hover">
                <thead>
                  <tr>
                    <th scope="col">S.no</th>
                    <th scope="col">Gym Name</th>
                    <th scope="col">Owner Name</th>
                    <th scope="col">Number</th>
                    <th scope="col">Plan</th>
                    <th scope="col">Status</th>
                    <th scope="col" className="cursor">
                      Action{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {status === "loading" && (
                    <>
                      {Array.from({ length: 10 }).map((_, index) => (
                        <tr key={index}>
                          <td>
                            <Skeleton circle={true} height={50} width={50} />
                          </td>
                          <td>
                            <Skeleton height={50} />
                          </td>
                          <td>
                            <Skeleton height={50} />
                          </td>
                          <td>
                            <Skeleton height={50} />
                          </td>
                          <td>
                            <Skeleton height={50} />
                          </td>
                          <td>
                            <Skeleton height={50} />
                          </td>
                          <td>
                            <Skeleton height={50} />
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                  {status === "failed" && (
                    <tr>
                      <td colSpan={7}>{error}</td>
                    </tr>
                  )}
                  {gyms.map((gym, index) => (
                    <tr key={index}>
                      <th scope="row">
                        <Wrap className="position-relative">
                          <Link to={`/gymprofile/${gym._id}`}>
                            {gym.image ? (
                              <img
                                src={gym.image}
                                alt=""
                                width={50}
                                height={50}
                                className="rounded-circle mx-1 img-fit"
                              />
                            ) : (
                              <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Unknown_person.jpg/694px-Unknown_person.jpg"
                                alt=""
                                width={50}
                                height={50}
                                className="rounded-circle mx-1 img-fit"
                              />
                            )}
                          </Link>
                          <span className="position-absolute bottom-0 start-0 rounded-circle">
                            {index + 1}
                          </span>
                        </Wrap>
                      </th>
                      <td>{gym.gymName}</td>
                      <td>{gym.ownerName}</td>
                      <td>{gym.phone}</td>
                      <td>
                        <Wrap className="text-white">
                          {gym.plan ? (
                            <>
                              {gym.plan.price}/{gym.plan.name}
                            </>
                          ) : (
                            <Wrap>Plan does not exist</Wrap>
                          )}
                        </Wrap>
                      </td>
                      <td>
                        {gym.plan ? (
                          <span className="bg-success px-2 rounded">
                            Continue
                          </span>
                        ) : (
                          <span className="bg-danger px-2 rounded">
                            Expired
                          </span>
                        )}
                      </td>
                      <td
                        className="cursor"
                        onClick={handleId(gym._id)}
                        data-bs-toggle="modal"
                        data-bs-target="#sendNotificationModal"
                        data-bs-dismiss="modal"
                      >
                        <i className="bi bi-bell"></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Wrap>
          </Wrap>
        </Wrap>

        <Model
          id="sendNotificationModal"
          title="Send Notification to Single Gym"
        >
          <Wrap className="my-3">
            <Input
              id="title"
              label="Title"
              required
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Wrap>
          <Wrap className="my-3">
            <Input
              id="description"
              label="Description"
              required
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Wrap>
          <Wrap className="my-1 text-center">
            <Button
              children={"Send  Notification"}
              onClick={SendNotificationButton}
            />
          </Wrap>
        </Model>
      </Wrap>
    </>
  );
}

export default Gyms;
