import React, { useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { login } from '../../ReduxToolkit/Slice/Login/LoginSlice'
import { useDispatch, useSelector } from "react-redux";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";

function Login() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const emailchange = (event) => {
    setemail(event.target.value);
  };
  const passwordchange = (event) => {
    setpassword(event.target.value);
  };
  const formdata = async (event) => {
    event.preventDefault();
    try {
      const result = await dispatch(login({ email, password }));
      if (result.payload) {
        history("/");
        setemail("");
        setpassword("");
      }
    } catch (error) {
      // console.log(`Error: ${error}`),
      alert(error + error);
    }
  };
  
  return (
    <>
     
      
      <div className="container-fluid login">
        <div className="row justify-content-center">
        <div className="col-lg-4 col-11 margin bg-white rounded shadow">
            <main className="form-signin">
              <form onSubmit={formdata}>
                <h1 className="my-3 text-center"><span className="fw-bold color">G</span>ymAutomation</h1>
                <h1 className="h3 text-center">Login</h1>
                <div className="form-floating my-3">
                  <Input placeholder="Email" type="email" value={email}
                    onChange={emailchange} color='warning' />
                </div>
                <div className="form-floating my-3">
                  <Input placeholder="********" type='password' value={password}
                    onChange={passwordchange} color='warning' />
                </div>
                <div className="d-grid my-3">
                  <Button children="Login" type="submit">
                    {status === "loading" ? (
                      <div className="spinner-border spinner-border-sm disabled text-white" style={{ color: "#1976D2" }} role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Login"
                    )}
                  </Button>
                </div>
              </form>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
