import { combineReducers } from "@reduxjs/toolkit";
import authSlice from './Slice/Login/LoginSlice';
import GymSlice from "./Slice/Gym/GymSlice";
import planSlice from "./Slice/Plan/Plan";
import userPlan from "./Slice/User/User";
import NotificationSlice from './Slice/Notification/Notification'
import supportSlice from './Slice/Support/Support'
const rootReducer = combineReducers({
    auth: authSlice,
    gym:GymSlice,
    plan:planSlice,
    user:userPlan,
    Notification:NotificationSlice,
    support:supportSlice
    
})


export default rootReducer;