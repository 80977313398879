const BASE_URL="https://backend-ivory-kappa.vercel.app/"
export const loginapi =`${BASE_URL}api/admin/login`
export const getallgymApi=`${BASE_URL}api/gym`
export const update_gymAPi=`${BASE_URL}api/gym/update`
export const update_gym_plan_api=`${BASE_URL}api/gym/updatePlan`
export const register_gyms_api=`${BASE_URL}api/gym/register`
// plan
export const getallplanApi=`${BASE_URL}api/plan`
export const createplanApi=`${BASE_URL}api/plan/create`
export const updateplanApi=`${BASE_URL}api/plan/update`
export const deleteplanApi=`${BASE_URL}api/plan`
//user
export const getalluserApi=`${BASE_URL}api/user/admin/all/users`
export const getalluserbygymApi=`${BASE_URL}api/user/admin`
//notification
export const sendnotificationAllgymAPi=`${BASE_URL}api/notification/gym/createForAll`
export const sendnotificationgymAPi=`${BASE_URL}api/notification/gym/create`
export const getNotificationunreadApi=`${BASE_URL}api/notification/unread`
export const deleteNotificationunreadApi=`${BASE_URL}api/notification/delete`
// export const verifynotificationApi=`${BASE_URL}api/notification/gym/create`
// support
export const supportAPi=`${BASE_URL}api/support`
//totla data

export const total=`${BASE_URL}api/admin/totalCounts`

