import Layout from './Layout/Layout'
import { RouterProvider, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import Home from './Page/Home/Home'
import Gyms from './Page/Gyms/Gyms'
import AddGyms from './Page/Gyms/AddGyms'
import Plan from './Page/Plan/Plan'
import Users from './Page/Users/Users'
import Notification from './Page/Notification/Notification'
import Support from './Page/Support/Support'
import FeedBack from './Page/Feedback/Feedback'
import Gymprofile from './Page/Gyms/GymProfile'
import Login from './Page/Login/Login'
import UpdateGyms from './Page/Gyms/UpdateGym'


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout />}>
      <Route path='' element={<Home />} />
      <Route path='users' element={<Users />} />
      <Route path='gyms' element={<Gyms/>} />
      <Route path='addgyms' element={<AddGyms />} />
      <Route path='plan' element={<Plan/>} />
      <Route path='gymprofile/:id' element={<Gymprofile />} />
      <Route path='updateGyms/:id' element={<UpdateGyms />} />
      <Route path='notification' element={<Notification />} />
      <Route path='support' element={<Support />} />
      <Route path='feedback' element={<FeedBack />} />
      <Route path='login' element={<Login />} />
    </Route>
  )
)
function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default App
