import React from 'react';
import Navbar from '../components/Navabr/Navbar';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Siderbar from '../components/Sidebar/Siderbar';
import PrivateRoute from '../PrivateRoute';
import { BgColor, TextColor } from '../components/Color/Color';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Layout() {
  const location = useLocation();

  const isPathInList = (pathList) => {
    return pathList.some(path => location.pathname === path);
  }

  const hideSidebarNavbarPaths = ['/login', '/forgetPassword'];

  const shouldHideSidebarNavbar = isPathInList(hideSidebarNavbarPaths);

  return (
    <>
      <ToastContainer/>
      <div className="container-fluid">
        <div className="row">
          <div className={`bg-${BgColor} text-white mb-2`}>
            {!shouldHideSidebarNavbar && <Navbar />}
          </div>
          {!shouldHideSidebarNavbar && (
            <div className={`col-lg-2 shadow d-none d-lg-block ${TextColor} text-${BgColor}`} style={{ height: "100vh" }}>
              <Siderbar />
            </div>
          )}
          <div style={{ height: "100vh", overflowY: "auto" }} className={`${shouldHideSidebarNavbar ? "col-lg-12" : "col-lg-10 bg-light border-start"}`}>

            <div className="">
              <PrivateRoute Component={Outlet} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout;
