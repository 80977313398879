import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {getallplanApi,createplanApi,updateplanApi,deleteplanApi} from '../../../Api_url';
import { toast } from "react-toastify";


// create blog
const  token =  localStorage.getItem("token")
const headers = {
    'Content-Type': 'application/json', 
    'Authorization': `Bearer ${token}` 
};
export const createplan = createAsyncThunk('createplan/plan', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(createplanApi, data, { headers });
        return response.data.message;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});
// update
export const updateplan = createAsyncThunk('updateplan/plan', async ({selectedId,data}, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${updateplanApi}/${selectedId}`, data)
        return response.data.message;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
})
// get blog
export const getallplan = createAsyncThunk('getallplan/plan', async () => {
    try {
        const response = await axios.get(getallplanApi,{ headers });
        return response.data; // Assuming your API response contains a 'plan' property
    } catch (error) {
        throw new Error(error.message);
    }
});

// delete blog
export const deleteplan = createAsyncThunk('deleteplan/blog', async (planId, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${deleteplanApi}/${planId}`);
        return response.data.message; 
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});



const planSlice = createSlice({
    name: 'plan',
    initialState: {
        plans: [],
        singleplan:{},
        status: 'idle',
        error: null,
        message: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //create plan
            .addCase(createplan.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createplan.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(action.payload);
                }
                state.status = 'idle';

            })
            .addCase(createplan.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(action.payload);
                }
            })
            //update plan
            .addCase(updateplan.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateplan.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(action.payload);
                }
                state.status = 'idle';


            })
            .addCase(updateplan.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(action.payload);
                }
            })
            // //getplan
            .addCase(getallplan.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getallplan.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.plans = action.payload;
            })
            .addCase(getallplan.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
           
            // //delete plan
            .addCase(deleteplan.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteplan.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.plans = state.plans.filter(plan => plan._id !== action.payload);
                if (state.status === 'succeeded') {
                    toast.success("Plan Delete Succefully!!");
                }
                state.status = 'idle';


            })
            .addCase(deleteplan.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(action.payload);
                }
            });
    }
});

export default planSlice.reducer;
