import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import {
  sendnotificationAllgymAPi,
  sendnotificationgymAPi,
  getNotificationunreadApi,
  deleteNotificationunreadApi,
  verifynotificationApi,
} from "../../../Api_url";
const token = localStorage.getItem("token");
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
};

// send notification
export const sendnotificationAllgym = createAsyncThunk(
  "sendnotificationAllgym/Notification",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(sendnotificationAllgymAPi, data, {
        headers,
      });
      return response.data.message;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
// send notification
export const sendnotificationgym = createAsyncThunk(
  "sendnotificationgym/Notification",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(sendnotificationgymAPi, data);
      return response.data.message;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// get notification
export const getnotificationunread = createAsyncThunk(
  "getnotification/notification",
  async () => {
    try {
      const response = await axios.get(`${getNotificationunreadApi}`, {
        headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

// delete notification
export const deleteNotificationunread = createAsyncThunk(
  "deletenotifications/notification",
  async (notificationid, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${deleteNotificationunreadApi}/${notificationid}`,
        { headers }
      );
      return response.data._id;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
// status
// export const VerifyNotification = createAsyncThunk('Notification/VerifyNotification', async ({ id, data  }, { rejectWithValue }) => {
//     try {
//         const response = await axios.patch(`${verifynotificationApi}/${id}`, data);
//         const message = response.data.message;
//         return message;

//     } catch (error) {
//         return rejectWithValue(error.response.data.message);
//     }
// });

const NotificationSlice = createSlice({
  name: "Notification",
  initialState: {
    Notifications: [],
    singleNotification: {},
    status: "idle",
    error: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //create all notificatio
      .addCase(sendnotificationAllgym.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendnotificationAllgym.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload;
        if (state.status === "succeeded") {
          
          toast.success(action.payload)       }
        state.status = "idle";
      })
      .addCase(sendnotificationAllgym.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        if (state.status === "failed") {
          toast.error(action.payload);
        }
      })
      //create  notificatio
      .addCase(sendnotificationgym.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendnotificationgym.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload;
        if (state.status === "succeeded") {
          toast.success(action.payload);
          
        }
        state.status = "idle";
      })
      .addCase(sendnotificationgym.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        if (state.status === "failed") {
          toast.error(action.payload);
        }
      })
      //getnotifications
      .addCase(getnotificationunread.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getnotificationunread.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.Notifications = action.payload;
      })
      .addCase(getnotificationunread.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      //delete notifications
      .addCase(deleteNotificationunread.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteNotificationunread.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.Notifications.notificationData =
          state.Notifications.notificationData.filter(
            (notifications) => notifications.notificationid !== action.payload
          );
        if (state.status === "succeeded") {
          toast.success("Notification Delete Succefully!!");
        }
        state.status = "idle";
      })
      .addCase(deleteNotificationunread.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
    // verify
    //  .addCase(VerifyNotification.pending, (state) => {
    //     state.status = 'loading';
    // })
    // .addCase(VerifyNotification.fulfilled, (state, action) => {
    //     state.status = 'succeeded';
    //     state.message = action.payload;
    //     state.status = 'idle'
    // })
    // .addCase(VerifyNotification.rejected, (state, action) => {
    //     state.status = 'failed';
    //     state.error = action.error.message || 'Failed to update category';
    // })
  },
});

export default NotificationSlice.reducer;
