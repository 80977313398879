import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {getalluserApi,getalluserbygymApi} from '../../../Api_url'
// create blog
const  token =  localStorage.getItem("token")
const headers = {
    'Content-Type': 'application/json', // Example header, adjust as needed
    'Authorization': `Bearer ${token}` // Example Authorization header, adjust as needed
    // Add more headers if necessary
};
export const getalluser = createAsyncThunk('getalluser/user', async () => {
    try {
        const response = await axios.get(`${getalluserApi}/?searchQuary=&limit=50`,{ headers });
        return response.data; // Assuming your API response contains a 'plan' property
    } catch (error) {
        throw new Error(error.message);
    }
});

// get single blog
export const getalluserbygym = createAsyncThunk('getalluserbygym/user', async (id) => {
    try {
        const response = await axios.get(`${getalluserbygymApi}/${id}?searchQuary&limit=100`,{ headers });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
});
// export const updateBlog = createAsyncThunk('updateBlog/blog', async ({formData,id}, { rejectWithValue }) => {
//     try {
//         const response = await axios.put(`${UPDATE_BLOG_API}/${id}`, formData)
//         return response.data.message;
//     } catch (error) {
//         return rejectWithValue(error.response.data.message);
//     }
// })
// get blog
// export const getalluser = createAsyncThunk('getalluser/user', async () => {
//     try {
//         const response = await axios.get(getalluserApi,{ headers });
//         return response.data; // Assuming your API response contains a 'user' property
//     } catch (error) {
//         throw new Error(error.message);
//     }
// });

// delete blog
// export const deleteuser = createAsyncThunk('deleteuser/blog', async (deleteBlogId, { rejectWithValue }) => {
//     try {
//         const response = await axios.delete(`https://wave-mantra.vercel.app/api/blog/${deleteBlogId}`);
//         return response.data.message; // Assuming your API response contains a 'user' property
//     } catch (error) {
//         return rejectWithValue(error.response.data.message);
//     }
// });



const userSlice = createSlice({
    name: 'user',
    initialState: {
        users: [],
        getallgymuser:[],
        status: 'idle',
        error: null,
        message: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //create blog
            // .addCase(register_user.pending, (state) => {
            //     state.status = 'loading';
            // })
            // .addCase(register_user.fulfilled, (state, action) => {
            //     state.status = 'succeeded';
            //     state.message = action.payload;
            //     if (state.status === 'succeeded') {
            //         alert(state.message)
            //     }
            //     state.status = 'idle';

            // })
            // .addCase(register_user.rejected, (state, action) => {
            //     state.status = 'failed';
            //     state.error = action.payload;
            //     if (state.status === 'failed') {
            //         alert(state.error)
            //     }
            // })
            //update blog
            // .addCase(updateBlog.pending, (state) => {
            //     state.status = 'loading';
            // })
            // .addCase(updateBlog.fulfilled, (state, action) => {
            //     state.status = 'succeeded';
            //     state.message = action.payload;
            //     if (state.status === 'succeeded') {
            //         alert(state.message)
            //     }
            //     state.status = 'idle';

            // })
            // .addCase(updateBlog.rejected, (state, action) => {
            //     state.status = 'failed';
            //     state.error = action.payload;
            //     if (state.status === 'failed') {
            //         alert(state.error)
            //     }
            // })
            // //getuser
            .addCase(getalluser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getalluser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload;
            })
            .addCase(getalluser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // getalluserbygym
            .addCase(getalluserbygym.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getalluserbygym.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.getallgymuser = action.payload;
            })
            .addCase(getalluserbygym.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // //getsingleuser
            
            // //delete user
            // .addCase(deleteuser.pending, (state) => {
            //     state.status = 'loading';
            // })
            // .addCase(deleteuser.fulfilled, (state, action) => {
            //     state.status = 'succeeded';
            //     state.user = state.user.filter(user => user._id !== action.payload);
            //     // state.status = 'idle'
            // })
            // .addCase(deleteuser.rejected, (state, action) => {
            //     state.status = 'failed';
            //     state.error = action.payload;
            // });
    }
});

export default userSlice.reducer;
