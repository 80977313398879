import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {supportAPi} from '../../../Api_url'
// create blog
const token = localStorage.getItem("token")
const headers = {
    'Content-Type': 'application/json', 
    'Authorization': `Bearer ${token}`
};

export const update_support = createAsyncThunk('update_support/support', async ({id,updateData},{ rejectWithValue }) => {
    try {
        const response = await axios.patch(`${supportAPi}/update/${id}`, updateData)
        return response.data.message;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
})

// get support
export const getAllsupport = createAsyncThunk('getAllsupport/support', async () => {
    try {
        const response = await axios.get(`${supportAPi}?page&limit&searchQuary`);
        return response.data; 
    } catch (error) {
        throw new Error(error.message);
    }
});

// delete blog
export const delete_support = createAsyncThunk('deletesupport/support', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${supportAPi}/${id}`);
        return response.data.message;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});



const supportSlice = createSlice({
    name: 'support',
    initialState: {
        supports: [],
        status: 'idle',
        error: null,
        message: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
           
            //update support
            .addCase(update_support.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(update_support.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                state.status = 'idle';

            })
            .addCase(update_support.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            
            // //getsupport
            .addCase(getAllsupport.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllsupport.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.supports = action.payload;
            })
            .addCase(getAllsupport.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // //delete support
            .addCase(delete_support.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(delete_support.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.supports = state.supports.filter(support => support._id !== action.payload);
                if (state.status = 'succeeded') {
                    alert(action.payload)
                }
            })
            .addCase(delete_support.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status = 'failed') {
                    alert(action.payload)
                }
            });
    }
});

export default supportSlice.reducer;
