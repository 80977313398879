import React from "react";
import { useDispatch } from "react-redux";
import { sendnotificationAllgym } from "../../ReduxToolkit/Slice/Notification/Notification";
import Model from "../../components/Model/Model";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import Wrap from "../../components/Wrapper/Wrap";
// modal
function SendNotification() {
  // modal
  const dispatch = useDispatch();
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  // modal end
  const SendNotification = () => {
    dispatch(
      sendnotificationAllgym({ title: title, description: description })
    );
    setTitle("");
    setDescription("");
  };
  return (
    <>
      
        <Button children={"Send Notification"}  data-bs-toggle="modal"
        data-bs-target="#sendNotificationModalall"
        data-bs-dismiss="modal"/>
      
      <Model
        id="sendNotificationModalall"
        title="Send Notification to All Gyms"
      >
          <Input
            id="title"
            label="Title"
            required
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Input
            id="description"
            label="Description"
            required
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        <Wrap>
            { <Button children={"Send  Notification"} onClick={SendNotification} />}
        </Wrap>
      </Model>
    </>
  );
}

export default SendNotification;
