import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { update_gym, getsinglegym } from '../../ReduxToolkit/Slice/Gym/GymSlice';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

function UpdateGyms() {
    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [gymName, setGymName] = useState("");
    const [ownerName, setOwnerName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPincode] = useState("");
    const [memberInGym, setMemberInGym] = useState("100");
    const [paymentMode, setPaymentMode] = useState("");

    // For handling file input change
    const { singlegym } = useSelector((state) => state.gym);
    useEffect(() => {
        dispatch(getsinglegym(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (singlegym.gym) {
            setGymName(singlegym.gym.gymName);
            setOwnerName(singlegym.gym.ownerName);
            setPhone(singlegym.gym.phone);
            setEmail(singlegym.gym.email);
            setAddress(singlegym.gym.address);
            setCity(singlegym.gym.city);
            setState(singlegym.gym.state);
            setPincode(singlegym.gym.pincode);
            setMemberInGym(singlegym.gym.memberInGym);
            setPaymentMode(singlegym.gym.paymentMode);
        }
    }, [singlegym])

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            const formData = {
                  gymName : gymName,
                  ownerName:ownerName,
                  phone:phone,
                  email:email,
                  address:address,
                  city:city,
                  state:state,
                  pincode:pincode,
                  memberInGym:"100",
                  paymentMode:paymentMode
            }
            dispatch(update_gym({ formData, id }));
            navigate("/gyms")
        } catch (error) {
            console.log("Error in Submission", error);
        }

    };

    return (
        <div className="container-fluid marginTop">
            <div className="row justify-content-center">
                <div className="col-lg-12 shadow rounded py-2">
                    <h3>List Gyms</h3>
                    <h4 className='text-center'>Provide Gym Details</h4>
                    <form >
                        <div className="row my-2 justify-content-between">
                            <div className="col-lg-6 my-2">
                                <Input
                                    id="gym-name"
                                    required
                                    type="text"
                                    label="Gym Name"
                                    value={gymName}
                                    onChange={(e) => setGymName(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    id="owner-name"
                                    required
                                    type="text"
                                    label="Owner Name"
                                    value={ownerName}
                                    onChange={(e) => setOwnerName(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    id="phone-number"
                                    required
                                    label="Number"
                                    type='tel'
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    id="email"
                                    required

                                    label="Email"
                                    variant="outlined"
                                    type='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    id="address"
                                    required

                                    label="Address"
                                    type='text'
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    id="city"
                                    required

                                    label="City"
                                    type='text'
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    id="state"
                                    required
                                    label="State"
                                    type='text'
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <Input
                                    id="pincode"
                                    label="Pincode"
                                    required
                                    type='number'
                                    value={pincode}
                                    onChange={(e) => setPincode(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-6 my-2">
                                <div className="form-group">
                                    <label htmlFor="filterSelect">Payment Mode</label>
                                    <select
                                        className="form-control form-control-sm"
                                        id="filterSelect"
                                        value={paymentMode}
                                        label="Payment Mode"
                                        onChange={(e) => setPaymentMode(e.target.value)}
                                    >
                                        <option value={''}>Select Payment Mode</option>
                                        <option value={"Online"}>Online</option>
                                        <option value={"Offline"}>Offline</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='text-center'>
                            <Button children={"Update Details"} onClick={handleSubmit}/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UpdateGyms;
