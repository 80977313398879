import React from 'react'

function Wrap({children, className = "", ...props}) {
  return (
    <div className={`${className}`} {...props}>
      {children}
    </div>
  )
}

export default Wrap