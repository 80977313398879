import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getalluser } from '../../ReduxToolkit/Slice/User/User';
import Input from '../../components/Input/Input';
import PageTitle from '../../components/Title/PageTitle';
import Table from '../../components/Table/Table';
import Wrap from '../../components/Wrapper/Wrap';

function Users() {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const dispatch = useDispatch();
  const { users, status, error } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getalluser());
  }, [dispatch]);
  //   table destructure
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Number", accessor: "phone" },
    { label: "gymName", accessor: "gymName" },
    { label: "Slot", accessor: "timeSlot" },
    { label: "Address", accessor: "address" },

  ];
  const preprocessData = (data) => {
    return data.map((item) => ({
      ...item,
      gymName: item.gymData ? item.gymData.gymName : "",
      address: `${item.address}, ${item.state}, ${item.city}, ${item.pincode}`,

    }));
  };
  return (
    <Wrap className="container-fluid">
      <Wrap className="row justify-content-center">
        <Wrap className="col-lg-12 py-2">
          <PageTitle children={"Members"} />
          <Wrap className="row my-2 justify-content-between">
            <Wrap className="col-lg-2 col-6">
              <Wrap className="form-group">
                <label htmlFor="filterSelect">Filter</label>
                <select
                  className="form-control form-control-sm"
                  id="filterSelect"
                  value={age}
                  onChange={handleChange}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                </select>
              </Wrap>
            </Wrap>
            <Wrap className="col-lg-3 col-6">
              <Input label="Search" placeholder="Search Here" />
            </Wrap>
          </Wrap>
          <Table
            columns={columns}
            data={preprocessData(users || [])}
            status={status}
          />
        </Wrap>
      </Wrap>
    </Wrap>
  );
}

export default Users;
