import React, { useEffect, useState } from 'react'

import { getAllsupport, delete_support, update_support } from '../../ReduxToolkit/Slice/Support/Support';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector, useDispatch } from 'react-redux';
import Input from '../../components/Input/Input';
function Support() {
    const dispatch = useDispatch();
    const { supports, status ,error} = useSelector((state) => state.support)
    const [age, setAge] = React.useState('');
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    useEffect(() => {
        dispatch(getAllsupport())
    }, [dispatch])
    // delete 
    const handleDelete = (id) => {
        const isconfirmed = window.confirm("Are you sure you want to delete this support?")
        if (isconfirmed) {
            dispatch(delete_support(id))
        }
    }
    // update 
    const [statuss, setStatus] = useState(true);
    const handleupdate = (id) => {
        try {
            const selectedSupport = supports.find(support => support._id === id);
            const newData = !selectedSupport.status; // Toggle the status
            dispatch(update_support({ id, updateData: { status: newData } }));
            if (status === "loading") {
                <span>Loading</span>
            } else if (status === "succeeded") {
                setStatus(newData);
            } else if (status === "failed") {
                alert("Error updating support");
            }
        } catch (error) {
            throw Error(error);
        }
    }

    return (
        <div className="container-fluid marginTop">
            <div className="row justify-content-center">
                <div className="col-lg-12 shadow rounded py-2">
                    <h3>Support</h3>
                    <div className="row my-2 justify-content-between">
                        <div className="col-lg-2 col-6">
                        <div className="form-group">
                                <label htmlFor="filterSelect">Filter</label>
                                <select
                                    className="form-control form-control-sm"
                                    id="filterSelect"
                                    value={age}
                                    onChange={handleChange}
                                >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                </select>
                            </div>

                        </div>
                        <div className="col-lg-3 col-6">
                        <Input label="Search" placeholder="Search Here" />
                        </div>
                    </div>
                    <div className='d-flex justify-content-end my-2'>
                        {/* <Button variant="contained" color='error'><SupportAgentRoundedIcon /> <span className='mx-1'>Delete All</span></Button> */}
                    </div>
                    <div className='table-responsive'>
                        <table className="table table-dark table-striped rounded table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">S.no</th>
                                    <th scope="col">Gym Name</th>
                                    <th scope="col">Owner Name</th>
                                    <th scope="col">Numer</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {status === "loading" && (
                                    <tr>
                                        <td colSpan={8}>
                                            <Skeleton count={10} height={50} />
                                        </td>

                                    </tr>
                                )}
                                {status === "failed" && (
                                    <tr>
                                        <td colSpan={8}>{error}</td>
                                    </tr>
                                )}
                                {status === "succeeded" && (
                                    supports.map((_, index) => {
                                        const newdate = new Date(_.createdAt);
                                        const createData = newdate.toLocaleDateString("default", {
                                            day: "2-digit",
                                            month: "long",
                                            year: "numeric"
                                        });
                                        return (
                                            <tr key={index}>
                                                <th scope="row"><div>
                                                    {index + 1}
                                                </div></th>
                                                <td>{_.gymData ? _.gymData.gymName : ""}</td>
                                                <td>{_.gymData ? _.gymData.ownerName : ""}</td>
                                                <td>{_.gymData ? _.gymData.phone : ""}</td>
                                                <td>{_.description}</td>
                                                <td>{createData}</td>
                                                <td className='cursor' onClick={() => handleupdate(_._id)}>{_.status ? <span className='px-2 bg-success rounded'>Completed</span> : <span className='px-2 bg-warning rounded'>Pending</span>}</td>
                                                <td className='cursor' onClick={() => handleDelete(_._id)}> <i className='bi bi-trash'></i> </td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Support