import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getsinglegym, deletegym, update_gym_plan } from '../../ReduxToolkit/Slice/Gym/GymSlice';
import { getallplan } from '../../ReduxToolkit/Slice/Plan/Plan';
import { useParams } from 'react-router-dom';
import { getalluserbygym } from '../../ReduxToolkit/Slice/User/User';
import { Link, useNavigate } from 'react-router-dom';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Model from '../../components/Model/Model';

function Gymprofile() {
    const { id } = useParams()
    const navigaet = useNavigate()
    const dispatch = useDispatch()
    const { singlegym, status, error } = useSelector((state) => state.gym);
    const { getallgymuser } = useSelector((state) => state.user);
    const { plans } = useSelector((state) => state.plan);
   console.log(singlegym)
    // paln
    const [seteletplan, setseteletplan] = React.useState("");
    const [planStatus, setplanStatus] = React.useState(true);
    const updategymplan = (e) => {
        e.preventDefault();
        const plandata = {
            plan: seteletplan,
            status: planStatus,
        }
        console.log(plandata)
        dispatch(update_gym_plan({ id, plandata }))
    }
    useEffect(() => {
        dispatch(getsinglegym(id));
        dispatch(getalluserbygym(id));
        dispatch(getallplan())
    }, [dispatch, id]);


    // const newdate = new Date(singlegym.gym.createdAt);
    // const createData = newdate.toLocaleDateString("default", {
    //     day: "2-digit",
    //     month: "long",
    //     year: "numeric"
    // });

    // const newdate2 = new Date(singlegym.gym.planUpdatedOn);
    // const updateDate = newdate2.toLocaleDateString("default", {
    //     day: "2-digit",
    //     month: "long",
    //     year: "numeric"
    // });
    // const expirey = new Date(singlegym.gym.ExpiryDate);
    // const expireyDates = expirey.toLocaleDateString("default", {
    //     day: "2-digit",
    //     month: "long",
    //     year: "numeric"
    // });

    const delete_gym = () => {
        const isconfirmed = window.confirm('Are you sure to Delete this GYM?')
        if (isconfirmed) {
            dispatch(deletegym(id));
            navigaet('/gyms')
        }

    }
    return (

        <>
            <div>     
                <Model id='exampleModal' title='Update Banner'>
                    <form onSubmit={updategymplan}>
                        <div className="row text-start">
                        <div className='my-3'>
                                <label>Plan</label>
                                <select
                                    className="form-select form-select-sm" 
                                    value={seteletplan}
                                    label="Select Plan"
                                    onChange={(e) => setseteletplan(e.target.value)}
                                >    
                                   <option value="select plan">Select plan</option>
                                     {plans &&  plans.map((_) => (
                                        <option value={_._id} key={_._id}>{_.name} / ₹{_.price} ({_.duration} Month)</option>
                                    ))}
                                </select>
                            </div>

                        
                            <div className='my-3'>
                                <label>Status</label>
                                <select
                                    value={planStatus}
                                    onChange={(e) => setplanStatus(prevStatus => !prevStatus)}
                                    className="form-select form-select-sm" 
                                >                     
                                    <option value={true}>True</option>
                                    <option value={false}>False</option>
                                </select>
                            </div>
                            <div className={`modal-footer`}>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                                <Button type='submit' children={"update"}></Button>
                            </div>
                        </div>
                    </form>
                </Model>
            </div>
            {/*modal end  */}
            <div className="container-fluid marginTop">
                <div className="row justify-content-center">
                    <div className="col-lg-11 shadow rounded py-2">
                        <h3>Gym Profile</h3>

                        <div className="row my-2">
                            <div className="col-lg-4">
                                {status === "loading" && (
                                    <div className="col-lg-4">
                                        <div className="p-2">
                                            <Skeleton width={"100%"} height={"100%"} />
                                        </div>
                                    </div>
                                )}
                                {status === "failed" && (
                                    <p className='text-cente'>{error}</p>
                                )}
                                {status === "succeeded" && singlegym.gym && (
                                    <div className='bg-dark p-2 rounded fs-5'>
                                        <div className='d-flex justify-content-center'>
                                            <div>
                                                <div className='text-center'>
                                                    {singlegym.gym ? <img src={singlegym.gym.image} alt="" width={100} height={100} className='rounded-circle mx-1 img-fit' /> : <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Unknown_person.jpg/694px-Unknown_person.jpg" alt="" width={100} height={100} className='rounded-circle mx-1 img-fit' />}
                                                </div>
                                                <div>
                                                    <div className='text-white text-center'><b>{singlegym.gym.plan && singlegym.gym.plan ? <span></span> : <span className='bg-danger px-3 rounded my-1'>Expried</span>}</b></div>
                                                    <div className='text-white'><b><i className='bi bi-person'></i> Gym Name</b> : <span > {singlegym.gym.gymName}</span><br /></div>
                                                    <div className='text-white'>
                                                        {singlegym.gym.plan && singlegym.gym.plan ? (
                                                            <React.Fragment>
                                                                <b><i className='bi bi-person'></i>Plan</b> : {singlegym.gym.plan.price}/{singlegym.gym.plan.name}<br />
                                                            </React.Fragment>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </div>
                                                    <div className='text-white'><b><i className='bi bi-telephone'></i> Number</b> : {singlegym.gym.phone}<br /></div>
                                                </div>
                                                <div className='text-center'>
                                                    <i className='bi bi-star-fill text-warning'></i>
                                                    <i className='bi bi-star-fill text-warning'></i>
                                                    <i className='bi bi-star-fill text-warning'></i>
                                                    <i className='bi bi-star-fill text-warning'></i>
                                                    <i className='bi bi-star-half text-warning'></i>
                                                </div>
                                                <div className='my-2 text-center'>
                                                    <Link className='text-white' to={`/updateGyms/${singlegym.gym._id}`}>
                                                        <Button children={"Edit Profile"} bgColor='light' textColor='dark' />
                                                    </Link>
                                                    <Button children={"Update Plan"} bgColor='success' className='mx-1' data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-dismiss="modal" />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                )}

                            </div>
                            <div className="col-lg-8">
                                {status === "loading" && (
                                    <div className="col-lg-8">
                                        <div className="p-2">
                                            <Skeleton width={"100%"} height={"100%"} />
                                        </div>
                                    </div>
                                )}
                                {status === "failed" && (
                                    <p className='text-cente'>{error}</p>
                                )}
                                {status === "succeeded" && singlegym.gym && (
                                    <div className='px-3 py-2 rounded fs-5 bg-dark text-white' style={{ border: "1px solid black" }} >
                                        <p><i className='bi bi-person'></i> <b>Owner Name</b> : {singlegym.gym.ownerName}<br /></p>
                                        <p><i className='bi bi-person'></i> <b>Member In Gym</b> : {singlegym.TotalUserCount}<br /></p>
                                       
                                        <p><i className='bi bi-person'></i> <b>Created Date </b> : {singlegym.gym.createdAt}<br /></p>
                                        {singlegym.gym.plan && singlegym.gym.plan ? (
                                            <p>
                                                <b><i className='bi bi-person'></i>Expired</b> : {singlegym.gym.ExpiryDate}<br />
                                            </p>
                                        ) : (
                                            <div></div>
                                        )}
                                        <p><i className='bi bi-person'></i> <b>Email</b> : {singlegym.gym.email}<br /></p>
                                        <p><i className='bi bi-person'></i> <b>Address</b> : {singlegym.gym.address},{singlegym.gym.state},{singlegym.gym.city},{singlegym.gym.pincode}<br /></p>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="row my-2 justify-content-between">
                            <div className="col-lg-3 col-12">
                                <Input placeholder="Search" />
                            </div>
                            <div className='table-responsive my-2'>
                                <table className="table table-dark table-striped rounded table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.no</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">number</th>
                                            <th scope="col">Slot</th>
                                            <th scope="col">Address</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getallgymuser && getallgymuser.length > 0 && getallgymuser.map((user, index) => (
                                            <tr key={index}>
                                                <th scope="row">
                                                    <div>
                                                        {index + 1}
                                                        {user.image ? (
                                                            <img src={user.image} alt="User" width={50} height={50} className='rounded-circle mx-1 img-fit' />
                                                        ) : (
                                                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Unknown_person.jpg/694px-Unknown_person.jpg" alt="Unknown" width={50} height={50} className='rounded-circle mx-1 img-fit' />
                                                        )}
                                                    </div>
                                                </th>
                                                <td>{user.name}</td>
                                                <td>{user.phone}</td>
                                                <td>{user.timeSlot}</td>
                                                <td>{`${user.address}, ${user.state}, ${user.city}, ${user.pincode}`}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='text-center'>
                            <Button bgColor='danger' children={"Delete"} onClick={delete_gym}>Delete Profile</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Gymprofile