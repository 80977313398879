import React from "react";
import Input from "../../components/Input/Input";
import SendNotification from "../Gyms/SendNotification";
import Wrap from "../../components/Wrapper/Wrap";
import {
  getnotificationunread,
  deleteNotificationunread,
} from "../../ReduxToolkit/Slice/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Table from "../../components/Table/Table";
import PageTitle from "../../components/Title/PageTitle";
function Notification() {
  const dispatch = useDispatch();
  const { Notifications, status } = useSelector((state) => state.Notification);
  useEffect(() => {
    if (status === "idle") {
      dispatch(getnotificationunread());
    }
  }, [status, dispatch]);
  //   delete api
  const handleDelete = (notificationid) => {
    const Isconfirmed = window.confirm(
      "Are you sure! you want delete this notification ?"
    );
    if (Isconfirmed) {
      dispatch(deleteNotificationunread(notificationid));
    }
  };
  //   table destructure
  function formatDate(dateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const preprocessData = (data) => {
    return data.map((item) => ({
      ...item,
      description: item.description || "Send by User",
    }));
  };
  const columns = [
    { label: "Notification", accessor: "notification" },
    { label: "Date", accessor: "createdAt", formatter: formatDate },
  ];
  return (
    <Wrap className="container-fluid">
      <Wrap className="row justify-content-center">
        <Wrap className="col-lg-12 py-2">
          <PageTitle children={"Notification"} />
          <Wrap className="row my-2 justify-content-between">
            <Wrap className="col-lg-6 col-6">
              <SendNotification />
            </Wrap>
            <Wrap className="col-lg-3 col-6">
              <Input placeholder="Search Here" />
            </Wrap>
          </Wrap>
          <Table
            columns={columns}
            data={preprocessData(Notifications.notificationData || [])}
            status={status}
            onDelete={handleDelete}
          />
        </Wrap>
      </Wrap>
    </Wrap>
  );
}

export default Notification;
