import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { register_gyms_api, getallgymApi, update_gymAPi ,update_gym_plan_api} from '../../../Api_url'
// create blog
const token = localStorage.getItem("token")
const headers = {
    'Content-Type': 'application/json', 
    'Authorization': `Bearer ${token}`
};
export const register_gym = createAsyncThunk('register_gym/gym', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(register_gyms_api, data);
        return response.data.message;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

export const update_gym = createAsyncThunk('update_gym/gym', async ({ formData, id }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${update_gymAPi}/${id}`, formData)
        return response.data.message;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
})
export const update_gym_plan = createAsyncThunk('update_gym_plan/gym', async ({ plandata, id }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${update_gym_plan_api}/${id}`, plandata)
        return response.data.message;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
})
// get
export const getallgym = createAsyncThunk('getallgym/gym', async () => {
    try {
        const response = await axios.get(getallgymApi, { headers });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
});
// get getsingle gym
export const getsinglegym = createAsyncThunk('getsinglegym/gym', async (id) => {
    try {
        const response = await axios.get(`${getallgymApi}/${id}`, { headers });
        return response.data; 
    } catch (error) {
        throw new Error(error.message);
    }
});
// delete blog
export const deletegym = createAsyncThunk('deletegym/blog', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${getallgymApi}/${id}`);
        return response.data.message; // Assuming your API response contains a 'gym' property
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});



const gymSlice = createSlice({
    name: 'gym',
    initialState: {
        gyms: [],
        singlegym: {},
        status: 'idle',
        error: null,
        message: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //create 
            .addCase(register_gym.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(register_gym.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(action.payload)   
                }
                state.status = 'idle';

            })
            .addCase(register_gym.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(action.payload)        
                }
            })
            //update gym
            .addCase(update_gym.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(update_gym.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(action.payload)   
                }
                state.status = 'idle';

            })
            .addCase(update_gym.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(action.payload)   
                }
            })
            //update gym plan
            .addCase(update_gym_plan.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(update_gym_plan.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(action.payload)   
                }
                state.status = 'idle';

            })
            .addCase(update_gym_plan.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(action.payload)   
                }
            })
            // //getgym
            .addCase(getallgym.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getallgym.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.gyms = action.payload;
            })
            .addCase(getallgym.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
            // //getsinglegym
            .addCase(getsinglegym.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getsinglegym.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.singlegym = action.payload;
            })
            .addCase(getsinglegym.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // //delete gym
            .addCase(deletegym.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletegym.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.gyms = state.gyms.filter(gym => gym._id !== action.payload);
                if (state.status = 'succeeded') {
                    toast.success("Gym Delete successfully!!")   
                }
            })
            .addCase(deletegym.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status = 'failed') {
                    toast.success(action.payload) 
                }
            });
    }
});

export default gymSlice.reducer;
